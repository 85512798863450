import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class MessageService {

  // Observable string sources
  private selectedYearSource = new Subject<number>();
  private addedYearSource = new Subject<number>();
  private isAdminSource = new Subject<boolean>();
  private isModeratorSource = new Subject<boolean>();
  private isUserSource = new Subject<boolean>();


  // Observable string streams
  selectedYear$ = this.selectedYearSource.asObservable();
  addedYear$ = this.addedYearSource.asObservable();

  isAdmin$ = this.isAdminSource.asObservable();
  isModerator$ = this.isModeratorSource.asObservable();
  isUser$ = this.isUserSource.asObservable();

  // Service message commands
  selectYear(year: number) {
    this.selectedYearSource.next(year);
  }

  addYear(year: number) {
    this.addedYearSource.next(year);
  }

  isAdmin(isAdmin: boolean) {
    this.isAdminSource.next(isAdmin);
  }

  isModerator(isModerator: boolean) {
    this.isModeratorSource.next(isModerator);
  }

  isUser(isUser: boolean) {
    this.isUserSource.next(isUser);
  }

}