import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { forkJoin, Observable, ObservableInput } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UploadService } from 'src/app/core/services/upload.service';


@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.css']
})
export class ImageUploadComponent implements OnInit {

    selectedFiles?: FileList;
    selectedFileNames: string[] = [];

    progressInfos: any[] = [];
    message: string[] = [];

    previews: string[] = [];
    IMAGE_URL = '/api/image/';

    @Output() updateAction: EventEmitter<any> = new EventEmitter<any>();
    @Output() progress: EventEmitter<any> = new EventEmitter<any>();
    @Output() addTemp: EventEmitter<any> = new EventEmitter<any>();

    constructor(private uploadService: UploadService) { }

    ngOnInit(): void {
    }

    /**
   * on file drop handler
   */
    onFileDropped(event: any) {
        this.selectFiles(event);
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler(event: any) {
        this.selectFiles(event);
    }

    selectFiles(event: any): void {
        this.message = [];
        this.progressInfos = [];
        this.selectedFileNames = [];
        this.selectedFiles = event.target.files;

        this.previews = [];
        if (this.selectedFiles && this.selectedFiles[0]) {
            const numberOfFiles = this.selectedFiles.length;
            for (let i = 0; i < numberOfFiles; i++) {
                const reader = new FileReader();

                reader.onload = (e: any) => {
                    this.previews.push(e.target.result);
                    this.addTemp.emit({ "identifier": e.target.result, "originalname": this.selectedFiles![i].name});
                };

                reader.readAsDataURL(this.selectedFiles[i]);

                this.selectedFileNames.push(this.selectedFiles[i].name);
            }
            this.uploadFiles();
        }
    }

    uploadFiles(): void {
        this.message = [];
        const uploads: Observable<any>[] = [];
        if (this.selectedFiles) {
            for (let i = 0; i < this.selectedFiles.length; i++) {
                uploads.push(this.upload(i, this.selectedFiles[i]));
            }
            forkJoin(uploads).subscribe(() => {
                this.updateAction.emit();
            });
        }
    }

    upload(idx: number, file: File): Observable<any> {
        const uploadObservable = new Observable(observer => {
            this.progressInfos[idx] = { value: 0, fileName: file.name };

            if (file) {
                this.uploadService.uploadImage(file).subscribe(
                    (event: any) => {
                        if (event.type === HttpEventType.UploadProgress) {
                            this.progressInfos[idx].value = Math.round(100 * event.loaded / event.total);
                            this.progress.emit(this.progressInfos[idx]);
                        } else if (event instanceof HttpResponse) {
                            const msg = 'Uploaded the file successfully: ' + file.name;
                            this.message.push(msg);
                            observer.next(msg);
                            observer.complete();
                        }
                    },
                    (err: any) => {
                        //console.log(err);
                        this.progressInfos[idx].value = 0;
                        const msg = 'Could not upload the file: ' + file.name;
                        this.message.push(msg);
                        observer.error(msg);
                        observer.complete();
                    });
            }
        });
        return uploadObservable;
    }
}