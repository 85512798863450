import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/core/services/notification.service';

import { NGXLogger } from 'ngx-logger';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { TableColumn } from '../TableColumn';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../confirm-dialog/confirm-dialog.component';
import { Lightbox } from 'ngx-lightbox';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
    selector: 'app-simple-table-component',
    templateUrl: './simple-table.component.html',
    styleUrls: ['./simple-table.component.css']
})
export class SimpleTableComponent implements OnInit {

    public tableDataSource = new MatTableDataSource<any[]>([]);
    public displayedColumns: string[] = [];
    valid: any = {};
    isAdmin: boolean = false;

    @ViewChild(MatPaginator, { static: false }) matPaginator!: MatPaginator;
    @ViewChild(MatSort, { static: true }) matSort!: MatSort;
    @ViewChild(MatTable) table!: MatTable<any>;

    @Input() selection: any;
    @Input() tableColumns: TableColumn[] = [];
    @Input() tableLabel!: string;
    @Input() tableLabelSuffix!: string;
    @Input() sortColumn: string = "";
    @Input() sortDirection!: SortDirection;
    @Input() deleteOnlyAdmin: boolean = false;
    @Input() readOnly: boolean = false;

    @Input() isPageable = false;
    @Input() isSortable = false;
    @Input() isFilterable = false;
    @Input() isDraggable: boolean = false;
    @Input() hasSortId: boolean = false;
    @Input() paginationSizes: number[] = [5, 10, 15];
    @Input() defaultPageSize = this.paginationSizes[1];
    @Input() imageBaseUrl!: string;
    @Input() isEditMode!: boolean;
    private _album: any[] = [];


    @Input() set tableData(data: any[]) {
        this.setTableDataSource(data);
    }

    @Output() addAction: EventEmitter<any> = new EventEmitter<any>();
    @Output() editAction: EventEmitter<any> = new EventEmitter<any>();
    @Output() deleteAction: EventEmitter<any> = new EventEmitter<any>();
    @Output() deleteSelectedAction: EventEmitter<any> = new EventEmitter<any>();
    @Output() updateListAction: EventEmitter<any> = new EventEmitter<any>();
    @Output() reorderAction: EventEmitter<any> = new EventEmitter<any>();
    @Output() toggleAction: EventEmitter<any> = new EventEmitter<any>();

    constructor(private notificationService: NotificationService,
        
        private logger: NGXLogger,
        public dialog: MatDialog,
        private _lightbox: Lightbox,
        private authService: AuthenticationService) {
    }

    ngOnInit() {
        this.isAdmin = this.authService.getCurrentUser()?.isAdmin;
        let columnNames = this.tableColumns.map((tableColumn: TableColumn) => tableColumn.key);
        this.displayedColumns = columnNames;
    }

    ngOnDestroy(): void {
        this.tableDataSource = new MatTableDataSource<any[]>([]);
    }

    ngAfterViewInit() {
        this.tableDataSource.paginator = this.matPaginator;
    }

    setTableDataSource(data: any) {
        this.tableDataSource = new MatTableDataSource<any>(data);
        this.tableDataSource.paginator = this.matPaginator;
        this.tableDataSource.sort = this.matSort;
        const sortState: Sort = { active: this.sortColumn, direction: this.sortDirection };
        this.tableDataSource.sort.active = sortState.active;
        this.tableDataSource.sort.direction = sortState.direction;
        this.tableDataSource.sort.sortChange.emit(sortState);
        let columnNames = this.tableColumns.map((tableColumn: TableColumn) => tableColumn.key);
        this.displayedColumns = columnNames;
    }

    /*
     * Manipulation methods
     */

    addRow() {
        this.addAction.emit();
    }

    editRow(row: any) {
        this.editAction.emit(row);
    }

    toggle(row: any) {
        this.toggleAction.emit(row);
    }

    removeRow(id: number) {
        this.dialog
            .open(ConfirmDialogComponent, {
                data: new ConfirmDialogModel("Wirklich löschen?", "")
            })
            .afterClosed()
            .subscribe((confirm) => {
                if (confirm) {
                    this.deleteAction.emit(id);
                }
            });
    }

    removeSelectedRows() {
        const selected = this.selection.selected;
        this.dialog
            .open(ConfirmDialogComponent, {
                data: new ConfirmDialogModel("Alle löschen?", "")
            })
            .afterClosed()
            .subscribe((confirm) => {
                if (confirm) {
                    this.deleteSelectedAction.emit(selected);
                    this.selection.clear();
                }
            });
    }

    updateAction(event: any) {
        this.updateListAction.emit();
    }

    drop(event: CdkDragDrop<any[]>): void {
        moveItemInArray(this.tableDataSource.data, event.previousIndex, event.currentIndex);
        // Update current sorting
        this.reorderAction.emit(this.tableDataSource.data);
        this.table.renderRows();

    }

    /*
     * Helper
     */

    lastSelectedSegmentRow = 1; // this is the variable which holds the last selected row index
    clickHandler(event: any, lastRow: any) {
        //console.log(event, lastRow);
        if (event.shiftKey) {
            let obj: any[] = Object.assign([], this.tableDataSource.data).filter((val, i) => {
                return i > this.lastSelectedSegmentRow && i < lastRow
            });

            obj.forEach(e => this.selection.select(e))
        }
        this.lastSelectedSegmentRow = lastRow;
    }

    inputHandler(e: any, id: number, key: string) {
        if (!this.valid[id]) {
            this.valid[id] = {};
        }
        this.valid[id][key] = e.target.validity.valid;
    }

    disableDeleteAll() {
        this.selection.ro
        return this.selection.selected.length <= 0;
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.tableDataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.tableDataSource.data.forEach(row => this.selection.select(row));
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.tableDataSource.filter = filterValue.trim().toLowerCase();

        if (this.tableDataSource.paginator) {
            this.tableDataSource.paginator.firstPage();
        }
    }

    open(link: string, caption: string) {
        link = this.imageBaseUrl + link.replace("thumb_200_", "");
        this._album = [];
        this._album.push({
            src: link,
            caption: caption,
            thumb: link,
        });

        this._lightbox.open(this._album, 0);
    }

    onCompare = (a: any, b: any) => a.key;

}
