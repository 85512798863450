<div class="navbar-container" [class.example-is-mobile]="mobileQuery.matches">

    <!-- Top navbar -->
    <mat-toolbar class="navbar" color="primary">
        <button mat-icon-button (click)="snav.toggle()">
            <mat-icon>menu</mat-icon>
        </button>

        <a class="navbar-brand" [routerLink]="['/']" matTooltip="Home">
            <h1>
                RSCUE Race Control
            </h1>
        </a>
        <span class="navbar-spacer"></span>
        <img class="logo" src="assets/images/logo-padding-01.png" />
        <span class="navbar-spacer"></span>
        <div *ngIf="user">
            <button mat-button [matMenuTriggerFor]="userMenu">
                <mat-icon>person</mat-icon>
                <span fxShow fxHide.xs>
                    {{userName}}
                </span>
            </button>
            <mat-menu #userMenu="matMenu" xPosition="before" yPosition="above" [overlapTrigger]="false">
                <a mat-menu-item [routerLink]="['/auth/login']">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Log out</span>
                </a>
            </mat-menu>
        </div>
    </mat-toolbar>

    <mat-sidenav-container class="navbar-sidenav-container">
        <!-- Side nav -->
        <mat-sidenav #snav [opened]="!mobileQuery.matches" [mode]="mobileQuery.matches ? 'over' : 'side'"
            [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" class="sidenav">
            <mat-form-field appearance="fill" class="yearSelector">
                <mat-label>Jahr</mat-label>
                <mat-select [(value)]="selectedYear" (selectionChange)="onYearSelected()">
                    <mat-option [value]="option.id" *ngFor="let option of years">{{ option.year }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-nav-list>
                <a mat-list-item [routerLink]="['/index']" routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        dashboard
                    </mat-icon>
                    <p mat-line> Übersicht </p>
                </a>

                <a mat-list-item [routerLink]="['/heft']" routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        menu_book
                    </mat-icon>
                    <p mat-line> Programmheft </p>
                </a>

                <a mat-list-item [routerLink]="['/startlist']" routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        list_alt
                    </mat-icon>
                    <p mat-line> Startlisten </p>
                </a>

                <a mat-list-item [routerLink]="['/result']" routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        sports_score
                    </mat-icon>
                    <p mat-line> Ergebnisse </p>
                </a>

                <div *ngIf="user">
                    <mat-divider></mat-divider>
                    <a mat-list-item [routerLink]="['/admin']" routerLinkActive="active">
                        <mat-icon mat-list-icon>
                            admin_panel_settings
                        </mat-icon>
                        <p mat-line> Adminbereich </p>
                    </a>
                </div>
            </mat-nav-list>

        </mat-sidenav>

        <!-- Main content -->
        <mat-sidenav-content class="sidenav-content">

            <router-outlet></router-outlet>

        </mat-sidenav-content>
    </mat-sidenav-container>
    <div class="loading-container" *ngIf="spinnerService.visibility | async">
        <mat-spinner class="spinner" [diameter]="50" *ngIf="showSpinner"></mat-spinner>
    </div>
</div>