<div class="navbar-container" [class.example-is-mobile]="mobileQuery.matches">

    <!-- Top navbar -->
    <mat-toolbar class="navbar" color="primary">
        <button mat-icon-button (click)="snav.toggle()">
            <mat-icon>menu</mat-icon>
        </button>

        <a class="navbar-brand" [routerLink]="['/']" matTooltip="Home">
            <h1>
                RSCUE Race Control Panel
            </h1>
        </a>
        <span class="navbar-spacer"></span>
        <img class="logo" src="assets/images/logo-padding-01.png" />
        <span class="navbar-spacer"></span>
        <button mat-button [matMenuTriggerFor]="userMenu">
            <mat-icon>person</mat-icon>
            <span fxShow fxHide.xs>
                {{userName}}
            </span>
        </button>
        <mat-menu #userMenu="matMenu" xPosition="before" yPosition="above" [overlapTrigger]="false">
            <a mat-menu-item [routerLink]="['/auth/login']">
                <mat-icon>exit_to_app</mat-icon>
                <span>Log out</span>
            </a>
        </mat-menu>

    </mat-toolbar>

    <mat-sidenav-container class="navbar-sidenav-container">
        <!-- Side nav -->
        <mat-sidenav #snav [opened]="!mobileQuery.matches" [mode]="mobileQuery.matches ? 'over' : 'side'"
            [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" class="sidenav">
            <mat-form-field appearance="fill" class="yearSelector">
                <mat-label>Jahr</mat-label>
                <mat-select [(value)]="selectedYear" (selectionChange)="onYearSelected()">
                    <mat-option [value]="option.id" *ngFor="let option of years">{{ option.year }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-nav-list>
                <a mat-list-item [routerLink]="['/admin/dashboard']" routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        dashboard
                    </mat-icon>
                    <p mat-line> Dashboard </p>
                </a>

                <mat-divider></mat-divider>

                <h3 mat-subheader *ngIf="isAdmin || isModerator">Jährliche Daten</h3>

                <a mat-list-item *ngIf="selectedYearLegacy">
                    <mat-icon mat-list-icon>
                        visibility_off
                    </mat-icon>
                    <p mat-line> Nicht öffentliches Jahr </p>
                </a>
                
                <a mat-list-item *ngIf="!selectedYearLegacy && (isAdmin || isModerator)" [routerLink]="['/admin/basedata']" routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        foundation
                    </mat-icon>
                    <p mat-line> Stammdaten </p>
                </a>

                <a mat-list-item *ngIf="!selectedYearLegacy && (isAdmin || isModerator)" [routerLink]="['/admin/race']" routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        directions_bike
                    </mat-icon>
                    <p mat-line> Rennen </p>
                </a>

                <a mat-list-item *ngIf="!selectedYearLegacy && (isAdmin || isModerator)" [routerLink]="['/admin/sponsor']" routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        credit_score
                    </mat-icon>
                    <p mat-line> Sponsoren </p>
                </a>

                <a mat-list-item *ngIf="!selectedYearLegacy && (isAdmin || isModerator)" [routerLink]="['/admin/participants']" routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        groups
                    </mat-icon>
                    <p mat-line> Teilnehmer </p>
                </a>

                <a mat-list-item *ngIf="!selectedYearLegacy && (isAdmin || isModerator)" [routerLink]="['/admin/results']" routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        sports_score
                    </mat-icon>
                    <p mat-line> Ergebnisse </p>
                </a>

                <a mat-list-item *ngIf="!selectedYearLegacy && (isAdmin || isModerator)" [routerLink]="['/admin/article']" routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        article
                    </mat-icon>
                    <p mat-line> Artikel/Texte </p>
                </a>

                <a mat-list-item *ngIf="!selectedYearLegacy && (isAdmin || isModerator)" [routerLink]="['/admin/pages']" routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        auto_stories
                    </mat-icon>
                    <p mat-line> Heftseiten </p>
                </a>

                <a mat-list-item *ngIf="!selectedYearLegacy && (isAdmin || isModerator)" [routerLink]="['/admin/slides']" routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        connected_tv
                    </mat-icon>
                    <p mat-line> TV-Slides </p>
                </a>

                <a mat-list-item *ngIf="!selectedYearLegacy && (isAdmin || isModerator)" [routerLink]="['/admin/export']" routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        ios_share
                    </mat-icon>
                    <p mat-line> Export </p>
                </a>

                <mat-divider></mat-divider>
                
                <h3 mat-subheader>Allgemeine Daten</h3>
                
                <a mat-list-item *ngIf="isAdmin || isModerator" [routerLink]="['/admin/media']" routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        image
                    </mat-icon>
                    <p mat-line>Media</p>
                </a>

                <a mat-list-item *ngIf="isAdmin" [routerLink]="['/admin/year']" routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        calendar_month
                    </mat-icon>
                    <p mat-line>Jahre</p>
                </a>

                <a mat-list-item *ngIf="isAdmin" [routerLink]="['/admin/configuration']" routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        settings
                    </mat-icon>
                    <p mat-line>Globale Konfiguration </p>
                </a>

                <a mat-list-item *ngIf="isAdmin" [routerLink]="['/admin/user']" routerLinkActive="active">
                    <mat-icon mat-list-icon>
                        manage_accounts
                    </mat-icon>
                    <p mat-line>Benutzer </p>
                </a>
                
                <mat-divider></mat-divider>
                
                <a mat-list-item [routerLink]="['/']">
                    <mat-icon mat-list-icon>
                        lock_open
                    </mat-icon>
                    <p mat-line> Öffentlicher Bereich </p>
                </a>
                <mat-divider></mat-divider>

                <a mat-list-item [routerLink]="['/auth/login']">
                    <mat-icon mat-list-icon>exit_to_app</mat-icon>
                    <p mat-line> Log out </p>
                </a>
            </mat-nav-list>

        </mat-sidenav>

        <!-- Main content -->
        <mat-sidenav-content class="sidenav-content">

            <router-outlet></router-outlet>

        </mat-sidenav-content>
    </mat-sidenav-container>
    <div class="loading-container" *ngIf="spinnerService.visibility | async">
        <mat-spinner class="spinner" [diameter]="50" *ngIf="showSpinner"></mat-spinner>
    </div>
</div>