<div *ngIf="!upload">
    <button mat-stroked-button (click)="addRow()">
        {{tableLabel}} hinzufügen
    </button>
    <button *ngIf="(deleteOnlyAdmin && isAdmin) || (!deleteOnlyAdmin)" mat-stroked-button color="warn" [disabled]="disableDeleteAll()"
        (click)="removeSelectedRows()">
        {{tableLabel}}{{tableLabelSuffix}} löschen
    </button>
</div>

<div *ngIf="upload">
    <button mat-stroked-button (click)="showUpload=!showUpload">
        <span *ngIf="!showUpload">{{tableLabel}}{{tableLabelSuffix}} hinzufügen</span>
        <span *ngIf="showUpload">Upload Bereich schließen</span>
    </button>
    <button *ngIf="(deleteOnlyAdmin && isAdmin) || (!deleteOnlyAdmin)" mat-stroked-button color="warn" [disabled]="disableDeleteAll()"
        (click)="removeSelectedRows()">
        {{tableLabel}}{{tableLabelSuffix}} löschen
    </button>
    <app-image-upload 
        *ngIf="showUpload"
        (updateAction)="updateAction($event)"
        (progress)="progress($event)"
        (addTemp)="addTemp($event)"></app-image-upload>
</div>

<div class="filter-header" *ngIf="isFilterable">
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event)" placeholder="Filter">
    </mat-form-field>
</div>
<mat-table [dataSource]="tableDataSource" matSort cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListDisabled]="!isDraggable || isEditMode">
    <ng-container [matColumnDef]="col.key" *ngFor="let col of tableColumns">
        <mat-header-cell mat-sort-header [disabled]="!col.isSortable" *matHeaderCellDef>
            <mat-checkbox *ngIf="col.type=='isSelected'" (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
            {{ col.label }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index;">
            <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
                <ng-container *ngSwitchCase="'isSelected'">
                    <mat-checkbox (change)="$event ? selection.toggle(element) : null"
                        [checked]="selection.isSelected(element)"
                        (click)="clickHandler($event, tableDataSource.paginator ? i + (tableDataSource.paginator.pageIndex * tableDataSource.paginator.pageSize) : i)"></mat-checkbox>
                </ng-container>
                <div class="btn-edit" *ngSwitchCase="'isEdit'">
                    <button mat-icon-button (click)="element.isEdit = !element.isEdit; isEditMode = !isEditMode;">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button *ngIf="(deleteOnlyAdmin && isAdmin) || (!deleteOnlyAdmin)" color="warn" (click)="removeRow(element.id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <img (click)="open(element[col.key], '')" 
                    class="table-image" 
                    *ngSwitchCase="'image'" 
                    src="{{imageBaseUrl}}thumbs/{{ element[col.key] }}" />
                <div *ngSwitchCase="'json'"><p *ngFor="let meta of element[col.key] | keyvalue:onCompare">{{ meta.key }}: {{meta.value}}</p></div>
                <span *ngSwitchCase="'boolean'">
                    <span *ngIf="element[col.key]"><mat-icon style="color: green">check_circle</mat-icon></span>
                    <span *ngIf="!element[col.key]"><mat-icon style="color: red">cancel</mat-icon></span>
                </span>
                <span *ngSwitchCase="'date'">
                    {{ element[col.key] | date: 'dd.MM.y HH:mm:ss':'+000' }}
                </span>
                <span *ngSwitchDefault>
                    {{ element[col.key] }}
                </span>
                <div *ngSwitchCase="'toggle'">
                    <mat-slide-toggle (change)="toggle(element)" [(ngModel)]="element[col.key]"></mat-slide-toggle>
                </div>
            </div>
            <div [ngSwitch]="col.type" *ngIf="element.isEdit">
                <div *ngSwitchCase="'isSelected'"></div>
                    <div class="btn-edit" *ngSwitchCase="'isEdit'">
                        <div *ngIf="!element.uploadPreview">
                            <button mat-icon-button (click)="abort(element)">
                                <mat-icon>cancel</mat-icon>
                            </button>
                            <button mat-icon-button (click)="editRow(element)"
                                [disabled]="disableSubmit(element.id)">
                                <mat-icon>task_alt</mat-icon>
                            </button>
                        </div>
                    </div>
                <mat-form-field class="form-input" *ngSwitchCase="'date'" appearance="fill">
                    <mat-label>Datum auswählen</mat-label>
                    <input matInput [matDatepicker]="picker" [disabled]="col.disabled! || element.uploadPreview" [(ngModel)]="element[col.key]" />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="fill" *ngSwitchCase="'select'">
                    <mat-label>{{ col.label }}</mat-label>
                    <mat-select [(ngModel)]="element[col.key]">
                        <mat-option [value]="option" *ngFor="let option of col.options">{{ option }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngSwitchCase="'image'">
                    <div class="upload-preview" *ngIf="element.uploadPreview">
                        <img (click)="open(element[col.key], '')" 
                        class="table-image" 
                        src="{{ element[col.key] }}" />
                        <mat-progress-bar id="progress_{{element.originalname}}" value="{{progressBar[element.originalname]}}"></mat-progress-bar>
                    </div>
                    <div *ngIf="!element.uploadPreview">
                        <img (click)="open(element[col.key], '')" 
                        class="table-image" 
                        src="{{imageBaseUrl}}thumbs/{{ element[col.key] }}" />
                    </div>
                </div>
                <div *ngSwitchCase="'boolean'">
                    <mat-form-field>
                        <mat-slide-toggle [(ngModel)]="element[col.key]"></mat-slide-toggle>
                        <textarea matInput hidden></textarea>
                    </mat-form-field>
                </div>
                <div *ngSwitchCase="'toggle'">
                    <mat-form-field>
                        <mat-slide-toggle [(ngModel)]="element[col.key]"></mat-slide-toggle>
                        <textarea matInput hidden></textarea>
                    </mat-form-field>
                </div>
                <div *ngSwitchCase="'json'"><p *ngFor="let meta of element[col.key] | keyvalue:onCompare">{{ meta.key }}: {{meta.value}}</p></div>
                <mat-form-field class="form-input" *ngSwitchDefault>
                    <input matInput (keyup.enter)="editRow(element)" [required]="col.required!" [pattern]="col.pattern!" [disabled]="col.disabled! || element.uploadPreview" [type]="col.type"
                        [(ngModel)]="element[col.key]"
                        (change)="inputHandler($event, element.id, col.key)"
                        (blur)="inputHandler($event, element.id, col.key)" />
                </mat-form-field>
            </div>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; let i = index; columns: displayedColumns" cdkDrag></mat-row>
</mat-table>
<mat-paginator *ngIf="isPageable"
[pageSizeOptions]="paginationSizes"
[pageSize]="defaultPageSize"
showFirstLastButtons></mat-paginator>