<div *ngIf="!readOnly">
    <button mat-stroked-button (click)="addRow()" [disabled]="isEditMode">
        {{tableLabel}} hinzufügen
    </button>
    <button *ngIf="(deleteOnlyAdmin && isAdmin) || (!deleteOnlyAdmin)" mat-stroked-button color="warn" [disabled]="disableDeleteAll() || isEditMode"
        (click)="removeSelectedRows()">
        {{tableLabel}}{{tableLabelSuffix}} löschen
    </button>
</div>

<div class="filter-header" *ngIf="isFilterable">
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event)" placeholder="Filter">
    </mat-form-field>
</div>
<mat-table [dataSource]="tableDataSource" matSort [matSortActive]="sortColumn" [matSortDirection]="sortDirection" cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListDisabled]="!isDraggable || isEditMode">
    <ng-container [matColumnDef]="col.key" *ngFor="let col of tableColumns">
        <mat-header-cell mat-sort-header [disabled]="!col.isSortable" *matHeaderCellDef>
            <mat-checkbox *ngIf="col.type=='isSelected'" (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
            {{ col.label }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index;">
            <div [ngSwitch]="col.type">
                <ng-container *ngSwitchCase="'isSelected'">
                    <mat-checkbox (change)="$event ? selection.toggle(element) : null"
                        [checked]="selection.isSelected(element)" (click)="clickHandler($event, tableDataSource.paginator ? i + (tableDataSource.paginator.pageIndex * tableDataSource.paginator.pageSize) : i)"></mat-checkbox>
                </ng-container>
                <div class="btn-edit" *ngSwitchCase="'isEdit'">
                    <div *ngIf="!readOnly">
                        <button mat-icon-button (click)="editRow(element.id)" [disabled]="isEditMode">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button *ngIf="(deleteOnlyAdmin && isAdmin) || (!deleteOnlyAdmin)" mat-icon-button color="warn"
                            (click)="removeRow(element.id)" [disabled]="isEditMode">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
                <img (click)="open(element[col.key], '')" class="table-image" *ngSwitchCase="'image'"
                    src="{{imageBaseUrl}}thumbs/{{ element[col.key] }}" />
                <div *ngSwitchCase="'json'">
                    <p *ngFor="let meta of element[col.key] | keyvalue:onCompare">{{ meta.key }}: {{meta.value}}</p>
                </div>
                <span *ngSwitchCase="'date'">
                    {{ element[col.key] | date: 'dd.MM.y HH:mm:ss':'+000' }}
                </span>
                <span *ngSwitchCase="'boolean'">
                    <span *ngIf="element[col.key]"><mat-icon style="color: green">check_circle</mat-icon></span>
                    <span *ngIf="!element[col.key]"><mat-icon style="color: red">cancel</mat-icon></span>
                </span>
                <div *ngSwitchCase="'toggle'">
                    <mat-slide-toggle (change)="toggle(element)" [(ngModel)]="element[col.key]"></mat-slide-toggle>
                </div>
                <span *ngSwitchDefault>
                    {{ element[col.key] }}
                </span>
            </div>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; let i = index; columns: displayedColumns" cdkDrag></mat-row>
</mat-table>
<mat-paginator *ngIf="isPageable" [pageSizeOptions]="paginationSizes" [pageSize]="defaultPageSize"
    showFirstLastButtons></mat-paginator>