import { Component, OnInit, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { timer } from 'rxjs';
import { Subscription } from 'rxjs';
import { Injectable, Inject } from '@angular/core';

import { AuthenticationService } from 'src/app/core/services/auth.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { YearService } from 'src/app/core/services/year.service';
import { MessageService } from 'src/app/core/services/message.service';
import { Year } from 'src/app/features/year/year-list/year-list.component';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {

    private _mobileQueryListener: () => void;
    mobileQuery: MediaQueryList;
    showSpinner: boolean = true;
    userName: string = "";
    isAdmin: boolean = false;
    isModerator: boolean = false;
    years: Array<Year> = [];

    private autoLogoutSubscription: Subscription = new Subscription;
    selectedYear!: number | null;
    selectedYearLegacy: boolean = true;
    selectedYearSlug!: string | null;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private media: MediaMatcher,
        public spinnerService: SpinnerService,
        private authService: AuthenticationService,
        private authGuard: AuthGuard,
        private yearService: YearService,
        private messageService: MessageService,
        @Inject('LOCALSTORAGE') private localStorage: Storage) {
        
        messageService.addedYear$.subscribe(
            year => {
                this.yearService.list().subscribe((res: any) => {
                    this.years = res;
                })
            }
        )
        this.mobileQuery = this.media.matchMedia('(max-width: 1000px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    ngOnInit(): void {
        //console.log("ddikdidiid")
        const user = this.authService.getCurrentUser();

        this.isAdmin = user.isAdmin;
        this.isModerator = user.isAdmin;
        this.userName = user.username;
        this.yearService.list().subscribe((res: any) => {
            this.years = res;
            this.selectedYearSlug = this.years[0].eventslug;
            this.selectedYear = this.years[0].id;
            this.selectedYearLegacy = this.years[0].legacy || false;
            if (this.localStorage.getItem('selectedYear')) {
                this.selectedYear = Number(this.localStorage.getItem('selectedYear'));
                this.selectedYearSlug = this.localStorage.getItem('selectedYearSlug');
                this.selectedYearLegacy = Boolean(JSON.parse(this.localStorage.getItem('selectedYearLegacy')));
            } else {
                this.localStorage.setItem('selectedYear', this.selectedYear.toString());
                this.localStorage.setItem('selectedYearLegacy', this.selectedYearLegacy.toString());
                this.localStorage.setItem('selectedYearSlug', this.selectedYearSlug);
            }
            this.messageService.selectYear(this.selectedYear);
        });

        // Auto log-out subscription
        const timer$ = timer(2000, 5000);
        this.autoLogoutSubscription = timer$.subscribe(() => {
            this.authGuard.canActivate();
        });
    }

    ngOnDestroy(): void {
        // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this._mobileQueryListener);
        this.autoLogoutSubscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.changeDetectorRef.detectChanges();
    }

    onYearSelected(): void {
        console.log("yearselected: "+this.selectedYear!.toString());
        this.localStorage.setItem('selectedYear', this.selectedYear!.toString());
        const yearObj = this.years.filter((u: Year) => u.id == this.selectedYear);
        console.log("yearObj: ",yearObj[0]);
        this.selectedYearLegacy = yearObj[0].legacy || false;
        this.localStorage.setItem('selectedYearLegacy', this.selectedYearLegacy!.toString());
        this.localStorage.setItem('selectedYearSlug', yearObj[0].eventslug);
        this.messageService.selectYear(this.selectedYear!);
        
    }
}
