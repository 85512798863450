import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

const UPLOAD_API = '/api/upload/';

@Injectable({
    providedIn: 'root'
})
export class UploadService extends ApiService {

    constructor(http: HttpClient) {
        super(http);
        super.API_URL = UPLOAD_API;
    }

    uploadImage(file: File): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();

        formData.append('file', file);

        return this.http.post(UPLOAD_API + "image", formData, {
            reportProgress: true,
            responseType: 'text',
            observe: 'events' 
        });
    }

    uploadResult(file: File): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();

        formData.append('file', file);

        return this.http.post(UPLOAD_API + "result", formData, {
            reportProgress: true,
            responseType: 'json',
            observe: 'events' 
        });
    }
}