import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    API_URL!: string;
    public httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    constructor(protected http: HttpClient) {
    }

    list(): Observable<any> {
        return this.http.get(
            this.API_URL,
            this.httpOptions
        )
    }

    getByRef(ref: number): Observable<any> {
        return this.http.get(
            this.API_URL + (ref != undefined ? "?id="+ref : ""),
            this.httpOptions
        )
    }

    get(id:number): Observable<any> {
        return this.http.get(
            this.API_URL + id,
            this.httpOptions
        )
    }

    add(object: any): Observable<any> {
        return this.http.post(
            this.API_URL,
            object,
            this.httpOptions
        )
    }

    update(id: number, object: any): Observable<any> {
        return this.http.put(
            this.API_URL + id,
            object,
            this.httpOptions
        )
    }

    delete(id: number): Observable<any> {
        return this.http.delete(
            this.API_URL + id,
            this.httpOptions
        )
    }

    deleteAll(objects: any[]): Observable<any> {
        return forkJoin(
            objects.map((object) => 
                this.http.delete<any>(`${this.API_URL}${object.id}`)
            )
        )
    }
}
