import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from './core/guards/admin.guard';

import { AuthGuard } from './core/guards/auth.guard';

const appRoutes: Routes = [
    
    // Public HTML pages
    {
        path: 'index',
        loadChildren: () => import('./public/index/index.module').then(m => m.IndexModule),
    },

    {
        path: 'heft',
        loadChildren: () => import('./public/heft/heft.module').then(m => m.HeftModule),
    },

    {
        path: 'startlist',
        loadChildren: () => import('./public/startlist/startlist.module').then(m => m.StartlistModule),
    },

    {
        path: 'result',
        loadChildren: () => import('./public/result/result.module').then(m => m.ResultModule),
    },

    // Admin
    {
        path: 'auth',
        loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
    },
    {
        path: 'admin/dashboard',
        loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/basedata',
        loadChildren: () => import('./features/basedata/basedata.module').then(m => m.BasedataModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/configuration',
        loadChildren: () => import('./features/configuration/configuration.module').then(m => m.ConfigurationModule),
        canActivate: [AuthGuard, AdminGuard]
    },
    {
        path: 'admin/user',
        loadChildren: () => import('./features/user/user.module').then(m => m.UserModule),
        canActivate: [AuthGuard, AdminGuard]
    },
    {
        path: 'admin/year',
        loadChildren: () => import('./features/year/year.module').then(m => m.YearModule),
        canActivate: [AuthGuard, AdminGuard]
    },
    {
        path: 'admin/race',
        loadChildren: () => import('./features/race/race.module').then(m => m.RaceModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/sponsor',
        loadChildren: () => import('./features/sponsor/sponsor.module').then(m => m.SponsorModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/article',
        loadChildren: () => import('./features/article/article.module').then(m => m.ArticleModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/export',
        loadChildren: () => import('./features/export/export.module').then(m => m.ExportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/pages',
        loadChildren: () => import('./features/page/page.module').then(m => m.PageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/slides',
        loadChildren: () => import('./features/slide/slide.module').then(m => m.SlideModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/participants',
        loadChildren: () => import('./features/participant/participant.module').then(m => m.ParticipantModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/results',
        loadChildren: () => import('./features/result/result.module').then(m => m.ResultModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin/media',
        loadChildren: () => import('./features/images/image.module').then(m => m.ImageModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'admin',
        redirectTo: 'admin/dashboard',
    },
    {
        path: '**',
        redirectTo: 'index',
        pathMatch: 'full'
    }

];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule { }
