import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { of, EMPTY } from 'rxjs';
import { environment } from 'src/environments/environment';

const AUTH_API = '/api/auth/';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    constructor(private http: HttpClient,
        @Inject('LOCALSTORAGE') private localStorage: Storage) {
    }

    login(email: string, password: string): Observable<any> {
        return this.http.post(
            AUTH_API + 'signin',
            {
                email,
                password,
            },
            httpOptions
        )
    }

    logout(): void {
        // clear token remove user from local storage to log user out
        this.localStorage.removeItem('selectedYear');
        this.localStorage.removeItem('selectedYearSlug');
        this.localStorage.removeItem('currentUser');
        this.http.post(AUTH_API + 'signout', { }, httpOptions);
    }

    getCurrentUser(): any {

        if (this.localStorage.getItem('currentUser')) {
            return JSON.parse(this.localStorage.getItem('currentUser')!);
        }
        
    }

    passwordResetRequest(email: string): Observable<any> {
        return this.http.post(
            AUTH_API + 'requestreset',
            {
                email,
            },
            httpOptions
        )
    }

    validateToken(token: string, userid: number): Observable<any> {
        return this.http.post(
            AUTH_API + 'validate',
            {
                token,
                userid
            },
            httpOptions
        )
    }

    changePassword(token: string, userid: number, password: string) {
        return this.http.post(
            AUTH_API + 'change',
            {
                token,
                userid,
                password
            },
            httpOptions
        )
    }

}
