import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CustomMaterialModule } from '../custom-material/custom-material.module';
import { LimitToPipe } from './pipes/limit-to.pipe';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ContentPlaceholderAnimationComponent } from './content-placeholder-animation/content-placeholder-animation.component';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { YesNoPipe } from './pipes/yes-no.pipe';
import { LayoutComponent } from './layout/layout.component';
import { FullEditTableComponent } from './table/full-edit-table/full-edit-table.component';
import { LightboxModule } from 'ngx-lightbox';
import { ImageUploadComponent } from '../features/images/upload/image-upload.component';
import { SimpleTableComponent } from './table/simple-table/simple-table.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PublicLayoutComponent } from '../public/layout/public-layout.component';

@NgModule({
    imports: [
        RouterModule,
        CustomMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        LightboxModule,
        CKEditorModule
    ],
    declarations: [
        ConfirmDialogComponent,
        ContentPlaceholderAnimationComponent,
        LimitToPipe,
        LocalDatePipe,
        YesNoPipe,
        LayoutComponent,
        PublicLayoutComponent,
        FullEditTableComponent,
        SimpleTableComponent,
        ImageUploadComponent,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        CustomMaterialModule,
        LimitToPipe,
        ConfirmDialogComponent,
        ContentPlaceholderAnimationComponent,
        LocalDatePipe,
        YesNoPipe,
        FullEditTableComponent,
        SimpleTableComponent,
        ImageUploadComponent,
        CKEditorModule
    ]
})
export class SharedModule { }
