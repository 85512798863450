import { Component, OnInit, ChangeDetectorRef, OnDestroy, AfterViewInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Injectable, Inject } from '@angular/core';

import { AuthenticationService } from 'src/app/core/services/auth.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { YearService } from 'src/app/core/services/year.service';
import { MessageService } from 'src/app/core/services/message.service';
import { Year } from 'src/app/features/year/year-list/year-list.component';

@Component({
    selector: 'app-public-layout',
    templateUrl: './public-layout.component.html',
    styleUrls: ['./public-layout.component.css']
})
export class PublicLayoutComponent implements OnInit, OnDestroy, AfterViewInit {

    private _mobileQueryListener: () => void;
    mobileQuery: MediaQueryList;
    showSpinner: boolean = true;
    userName: string = "";
    user: any;
    isAdmin: boolean = false;
    isModerator: boolean = false;
    isUser: boolean = false;
    years: Array<Year> = [];

    selectedYear!: number | null;
    selectedYearSlug!: string | null;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private media: MediaMatcher,
        public spinnerService: SpinnerService,
        private authService: AuthenticationService,
        private yearService: YearService,
        private messageService: MessageService,
        @Inject('LOCALSTORAGE') private localStorage: Storage) {
        
        messageService.addedYear$.subscribe(
            year => {
                this.yearService.list().subscribe((res: any) => {
                    this.years = res;
                })
            }
        )
        
        this.mobileQuery = this.media.matchMedia('(max-width: 1000px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    ngOnInit(): void {

        this.user = this.authService.getCurrentUser();
        if (this.user) {
            this.isAdmin = this.user.isAdmin;
            this.isModerator = this.user.isModerator;
            this.isUser = true;
            this.userName = this.user.username;
            this.messageService.isAdmin(this.isAdmin);
            this.messageService.isModerator(this.isModerator);
            this.messageService.isUser(this.isUser);
        }

        

        this.yearService.list().subscribe((res: any) => {
            this.years = res;
            this.years = this.years.filter((year: any) => (!year.legacy && year.active));
            
            this.selectedYearSlug = this.years[0].eventslug;
            this.selectedYear = this.years[0].id;
            if (this.localStorage.getItem('selectedYear')) {
                this.selectedYear = Number(this.localStorage.getItem('selectedYear'));
                this.selectedYearSlug = this.localStorage.getItem('selectedYearSlug');
            } else {
                this.localStorage.setItem('selectedYear', this.selectedYear.toString());
                this.localStorage.setItem('selectedYearSlug', this.selectedYearSlug);
            }
            this.messageService.selectYear(this.selectedYear);
        });
    }

    ngOnDestroy(): void {
        // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

    ngAfterViewInit(): void {
        this.changeDetectorRef.detectChanges();
    }

    onYearSelected(): void {
        //console.log("yearselected");
        this.localStorage.setItem('selectedYear', this.selectedYear!.toString());
        const slug = this.years.filter((u: Year) => u.id == this.selectedYear)
        this.localStorage.setItem('selectedYearSlug', slug[0].eventslug);
        this.messageService.selectYear(this.selectedYear!);
    }
}
