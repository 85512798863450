import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Year } from 'src/app/features/year/year-list/year-list.component';
import { ApiService } from './api.service';


const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class YearService extends ApiService{

    constructor( http: HttpClient) {
        super(http);
        super.API_URL = "/api/year/"
    }
}
